var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "extended-tables", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Simple Tables", link: "componetns/simple-tables" }
      }),
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: {
            color: "success",
            icon: "mdi-clipboard-text",
            inline: "",
            title: "Simple Table"
          }
        },
        [
          _c("v-simple-table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("#")]),
                _c("th", [_vm._v("Name")]),
                _c("th", [_vm._v("Job Position")]),
                _c("th", [_vm._v("Since")]),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v("\n            Salary\n          ")
                ]),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v("\n            Actions\n          ")
                ])
              ])
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("1")]),
                _c("td", [_vm._v("Andrew Mike")]),
                _c("td", [_vm._v("Developer")]),
                _c("td", [_vm._v("2013")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            € 99,225\n          ")
                ]),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  _vm._l(_vm.actions, function(action, i) {
                    return _c(
                      "v-btn",
                      {
                        key: i,
                        staticClass: "px-2 ml-1",
                        attrs: {
                          color: action.color,
                          "min-width": "0",
                          small: ""
                        }
                      },
                      [
                        _c("v-icon", {
                          attrs: { small: "" },
                          domProps: { textContent: _vm._s(action.icon) }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ]),
              _c("tr", [
                _c("td", [_vm._v("2")]),
                _c("td", [_vm._v("John Doe")]),
                _c("td", [_vm._v("Designer")]),
                _c("td", [_vm._v("2012")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            € 89,241\n          ")
                ]),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  _vm._l(_vm.actions, function(action, i) {
                    return _c(
                      "v-btn",
                      {
                        key: i,
                        staticClass: "px-1 ml-1",
                        attrs: { color: action.color, fab: "", "x-small": "" }
                      },
                      [
                        _c("v-icon", {
                          domProps: { textContent: _vm._s(action.icon) }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ]),
              _c("tr", [
                _c("td", [_vm._v("3")]),
                _c("td", [_vm._v("Alex Mike")]),
                _c("td", [_vm._v("Designer")]),
                _c("td", [_vm._v("2010")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            € 92,144\n          ")
                ]),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  _vm._l(_vm.actions, function(action, i) {
                    return _c(
                      "v-btn",
                      {
                        key: i,
                        staticClass: "ml-1",
                        attrs: {
                          color: action.color,
                          fab: "",
                          icon: "",
                          "x-small": ""
                        }
                      },
                      [
                        _c("v-icon", {
                          attrs: { small: "" },
                          domProps: { textContent: _vm._s(action.icon) }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ]),
              _c("tr", [
                _c("td", [_vm._v("4")]),
                _c("td", [_vm._v("Mike Monday")]),
                _c("td", [_vm._v("Marketing")]),
                _c("td", [_vm._v("2013")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            € 49,990\n          ")
                ]),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  _vm._l(_vm.actions, function(action, i) {
                    return _c(
                      "v-btn",
                      {
                        key: i,
                        staticClass: "px-1 ml-1",
                        attrs: { color: action.color, fab: "", "x-small": "" }
                      },
                      [
                        _c("v-icon", {
                          domProps: { textContent: _vm._s(action.icon) }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ]),
              _c("tr", [
                _c("td", [_vm._v("5")]),
                _c("td", [_vm._v("Paul Dickens")]),
                _c("td", [_vm._v("Communications")]),
                _c("td", [_vm._v("2015")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            € 69,201\n          ")
                ]),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  _vm._l(_vm.actions, function(action, i) {
                    return _c(
                      "v-btn",
                      {
                        key: i,
                        staticClass: "px-2 ml-1",
                        attrs: {
                          color: action.color,
                          "min-width": "0",
                          small: ""
                        }
                      },
                      [
                        _c("v-icon", {
                          attrs: { small: "" },
                          domProps: { textContent: _vm._s(action.icon) }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "py-3" }),
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3",
          attrs: {
            color: "success",
            icon: "mdi-clipboard",
            inline: "",
            title: "Checked Table"
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.selections,
              "hide-default-footer": "",
              "show-select": ""
            },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          { attrs: { align: "center", justify: "end" } },
                          [
                            _c("strong", { staticClass: "title" }, [
                              _vm._v("Total")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "display-1 font-weight-light ml-12"
                              },
                              [_vm._v("\n              € 12,999\n            ")]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "py-3" }),
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3",
          attrs: {
            color: "success",
            icon: "mdi-vuetify",
            inline: "",
            title: "Shopping Cart Table"
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.productHeaders,
              items: _vm.products,
              "hide-default-footer": ""
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: { src: item.src, "max-width": "75" }
                          })
                        ],
                        1
                      ),
                      _c("td", [
                        _c("div", {
                          staticClass: "purple--text headline",
                          domProps: { textContent: _vm._s(item.name) }
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "grey--text subtitle-1 font-weight-light"
                          },
                          [
                            _vm._v(
                              "\n              by " +
                                _vm._s(item.brand) +
                                "\n            "
                            )
                          ]
                        )
                      ]),
                      _c("td", {
                        domProps: { textContent: _vm._s(item.color) }
                      }),
                      _c("td", {
                        domProps: { textContent: _vm._s(item.size) }
                      }),
                      _c("td", {
                        staticClass: "text-right",
                        domProps: { textContent: _vm._s(item.price) }
                      }),
                      _c("td"),
                      _c("td", {
                        staticClass: "text-right",
                        domProps: { textContent: _vm._s(item.amount) }
                      })
                    ])
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("v-divider"),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          { attrs: { align: "center", justify: "end" } },
                          [
                            _c("strong", { staticClass: "title" }, [
                              _vm._v("Total")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "display-1 font-weight-light ml-12"
                              },
                              [_vm._v("\n              € 2,346\n            ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "div",
                      { staticClass: "text-right py-6" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-0",
                            attrs: { color: "info", default: "", rounded: "" }
                          },
                          [
                            _vm._v(
                              "\n            Complete Purchase\n            "
                            ),
                            _c("v-icon", [_vm._v("mdi-chevron-right")])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }